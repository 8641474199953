<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <div class="ps-page--shop">
    <div class="ps-container">
      
     
      <div class="ps-layout--shop1">
        
        <!-- <a class="FilterMainBtn" id="menuIcon1" onclick="openNav2()">Filter</a>

        <div class="ps-layout__left">
          <div class="desktopSidePannelall">
            <CategorySidebar
                :categories="getCategoryList"
                 @refreshpage="pageRefresh"
            ></CategorySidebar>
          </div>
        </div>

        <div id="mySidenav2" class="filterDivMain">
          <span
            href="javascript:void(0)"
            class="closebtn"
            onclick="closeNav2()"
            id="category_filter_close"
          >
            <img alt="" src="/assets/img/close-btn.png" />
          </span>
          <CategorySidebar
                :categories="getCategoryList"
                 @refreshpage="pageRefresh"
            ></CategorySidebar>
        </div> -->
        <div class="ps-layout__right mr_top25">
          <div class="ps-shopping ps-tab-root">
             
         
            <div class="ps-tabs">
              <div class="ps-tab active" id="tab-1">
                <div class="ps-prduct_box">
                  <div class="rowMainAll">
                    <!-- <template class="produtDeatilsDivFloat" v-if="parentId==''|| parentId==null" >
                        <template v-for="(category,index) in maincategory" :key="index">
                            <div class="produtDeatilsDivFloat">
                                 <ul>
                                     <li>
                                         <h4>
                                             <router-link :to="`/all-category?subCat=${category.catUrlKey}&pid=${category.catId}`">
                                                <span @click="pageRefresh(category.catUrlKey)">{{category.catName}}</span> 
                                            </router-link>
                                            </h4>
                                     </li>
                                 </ul>
                            </div>
                                
                        </template>                      
                            
                        </template> -->

                         <!-- <template > -->
                             <div class="p-4">
                                   <div class="row">
                               
                           
                                 <template v-for="(category1,index) in maincategory" :key="index">
                                   <div class="topCatBlock">  
                                  <div  style="float:left; width:100%">
                                            <h4 >
                                                <router-link :to="`/products/${category1.catUrlKey}`" class="nav-link" >
                                                                                    <span @click="pageRefresh(category1)"> {{category1.catName}}</span>
                                                </router-link>
                                           
                                            </h4>
                                  </div>
                                  
                                    <!-- <template  v-if="parentId==category1.catId"> -->
                                      
                                             <template v-for="(cat,index) in getCategoryList" :key="index">
                                            <div :class="`${cat.catUrlKey} topCatChildBlock`" v-if="category1.catId ==cat.parentId">
                                                                        <div  style="float:left; width:100%">

                                                                            <ul>
                                                                              
                                                                                <h4>
                                                                                <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" >
                                                                                    <span @click="pageRefresh(cat)">{{cat.catName}}</span>
                                                                                </router-link>
                                                                                </h4>

                                                                                  
                                                                                    
                                                                                            <template v-for="cat1 in getCategoryList" :key="cat1.catId">
                                                                                                
                                                                                                    <li  v-if="cat.catId ==cat1.parentId">
                                                                                                        <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link" >
                                                                                                            <span @click="pageRefresh(cat1)">{{cat1.catName}}</span>
                                                                                                        </router-link>
                                                                                                        
                                                                                                    </li>
                                                                                            </template>
                                                                                   
                                                                                    
                                                                            
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                    </template>
                                    <!-- </template> -->
                                   </div>
                                 </template>
                                     
                            </div>
                             </div>
                          
                     
                    <!-- </template> -->
                  </div>

                  <EmptyItem   v-if="getCategoryList && getCategoryList.length < 1 "></EmptyItem>
                </div>

               
               
               
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import Breadcrumb from "@/components/Breadcrumb";
import EmptyItem from "@/components/EmptyItem";
// import CategoryCard from "@/components/CategoryCard"
// import CategorySidebar from "@/components/CategorySidebar";
import Config from  '../config';
import $ from "jquery";
export default {
    name:"Morecategory",
     components: {  
      Breadcrumb,
      EmptyItem,
    //   CategoryCard,
    //   CategorySidebar
     },
     watch:{
         $route(route){
                if(route.query.pid!=''){
                    this.parentId=route.query.pid;
                    this.CategoryUrlKey=route.query.subCat;
                    // this.getCategoryName();
                }
         }
     },
     data(){
         return {
             urls: [
                    {
                    path: "/",
                    name: "Home",
                    },

                    {
                    path: "/category-more",
                    name: "Category List",
                    },
                ],
            web_admin_url:'',
            subCatUrlKey:'',
            parentId:this.$route.query.pid,
            categoryName:'',
            CategoryUrlKey:'',
            parentCatUrlKey:'',
            parentPId:'',
            parentCatName:'',
            categoryList:''

         }
     },
    computed:{
        ...mapGetters(['getCategoryList']),
        maincategory(){
               let mainCatArr =[];
                     this.getCategoryList.forEach(function(item){
                        
                                if(item.parentId ==1){
                                    mainCatArr.push(item);
                                }
                        });
                        return mainCatArr;
                        
                    }
       
    },
    methods:{
          ...mapActions(['storeCategoryList']),
    // getCategoryName(){     
    //    let category='';
    //    let $this =this;
     
    //       $this.storeCategoryList().then(function(response){
    //              category = response.data.Data.filter(function(item){
                   
    //                 if (item.catUrlKey == $this.$route.query.subCat) {
    //                     return true;
    //                 }
    //                 return false;
    //             });

    //            if(category!=''){                
    //                $this.categoryName=category[0].catName; 
    //                $this.CategoryUrlKey =$this.$route.query.subCat;
    //                $this.parentId=$this.$route.query.pid;
    //                let parts =category[0].code.split("#");
    //                let parentCat='';
                   
    //                if(parts!=''){
    //                      let subValue=0;
    //                     if(parts.length==4){
    //                       subValue=3;
    //                     }else if(parts.length==3){
    //                       subValue=2;
    //                     }
                       
    //                   parentCat = response.data.Data.filter(function(item){
                    
                        
    //                     if (item.catId == parts[parts.length-subValue]) {
    //                         return true;
    //                     }
    //                     return false;
    //                   });
    //                }
    //                if(parentCat!=''){
    //                     $this.parentCatName=parentCat[0].catName;
    //                     $this.parentPId=parentCat[0].catId;
    //                     $this.parentCatUrlKey=parentCat[0].catUrlKey
    //                }                 
    //               $this.updateMetaDetails(category);
    //               $this.categoryList = response.data.Data;
                 

                   
    //            }
    //       });
        
        
      
    // },
    pageRefresh(url_key) {
              this.$emit("refreshpage", url_key);
            },
      updateMetaDetails(item){
       
         item[0].metaTitle?document.title = item[0].metaTitle:'';
         item[0].metaDescription?$('meta[name=description]').attr('content',  item[0].metaDescription):'';
         item[0].metaKeywords?$('meta[name=keywords]').attr('content', item[0].metaKeywords):'';
      },
        check3levelCat(parantCat){

            let isThirdLevel =false;          
                this.getCategoryList.forEach(function(item){                  
                        if(item.parentId ==parantCat){
                                isThirdLevel =true                    
                        }
                });
              return isThirdLevel;
        },

    },
    mounted(){
        this.web_admin_url = Config.WEB_ADMIN_URL;
       // this.getCategoryName();
       
        
    }
}
</script>
<style scoped>
      .more_cat{
          display: inline-block;
          color: #fff;
          background-color: #023f88;
          border-color: #023f88;
      }
      .topCatBlock{
          float: left;
          width: 100%;
          border-bottom: 1px dashed #ddd;
          padding-bottom: 20px;
          margin-bottom:20px
      }
      .topCatChildBlock{
          float:left; 
          width:20%;
          overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
       @media screen and (max-width: 992px){
            .produtDeatilsDivFloat {
                    width: 33.33%!important;
            }
            .topCatChildBlock{
                 width: 50%!important;
            }
       }
       @media screen and (max-width: 767px){
          
             .topCatChildBlock{
                 width: 100%!important;
            }
       }
        @media screen and (max-width: 567px){
            .produtDeatilsDivFloat {
                    width: 50%!important;
            }

             .filterDivMain .closebtn{
              top:80px!important;
            }
             .topCatBlock{
                 width: 100%!important;
            }
            .topCatChildBlock{
                 width: 100%!important;
            }
       }
</style>